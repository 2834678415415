<template>
  <custom-selector
    class="users-role-selector"
    v-bind="$attrs"
    :items="nonCourierTypes"
    :value="$attrs.value"
    :formattedValue="formattedSelectedRole"
    v-on="$listeners">
    <template v-slot:default="slotProps">
      <slot :item="slotProps.item"/>
    </template>
  </custom-selector>
</template>

<script>
import { mapGetters } from 'vuex'

const CustomSelector = () => import('@components/CustomSelector')

export default {
  name: 'users-role-selector',
  components: {
    CustomSelector
  },
  props: CustomSelector.props,
  computed: {
    ...mapGetters('user', [
      'nonCourierTypes'
    ]),
    formattedSelectedRole() {
      const isMultiple = this.$attrs.multiple || this.$attrs.multiple === '' // empty string counts as true for props
      if (!isMultiple) {
        return this.$attrs.value
      } else {
        // don't filter if no roles are selected
        if ([0, this.nonCourierTypes.length].includes(this.$attrs.value.length)) {
          return this.$t('users.filter.allRoles')
        }
        return this.$attrs.value.join(', ')
      }
    }
  }
}
</script>
